.priceTableContainer {
  padding: 20px;
}

.priceTable {
  width: 100%;
  margin-bottom: 0;
}
.priceTable td {
  border: 1px dashed #c8c8c8;
  text-align: center;
  vertical-align: middle;
}
.priceTable tr:first-child td {
  border-top: 0;
}
.priceTable tr:last-child td {
  border-bottom: 0;
}
.priceTable td:first-child {
  background-color: #f0f0f0;
  border-left: 0;
}
.priceTable td:last-child {
  border-right: 0;
}

@media screen and (max-width: 1036px) {
  .priceTableContainer {
    padding: 10px;
  }
  .priceTable td {
    padding: 10px 5px;
  }
}
