.qaContainer {
  padding: 20px;
}
.qaContainer a {
  color: #fac83c;
  border-bottom: 1px solid;
}
.qaContainer li {
  position: relative;
  padding-bottom: 10px;
}
.qaContainer li:last-child {
  padding-bottom: 0;
}
.qaContainer li:before {
  content: "・";
}
