.modalContainer {
  width: 1000px;
  height: 80vh;

  @media screen and (max-width: 1035px) {
    width: calc(100% - 20px);
  }
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.answerBtn {
  margin-right: 8px;
}
