.imageContainer {
  display: flex;
  @media screen and (max-width: 1035px) {
    flex-direction: column;
  }
}

.link {
  @media screen and (max-width: 1035px) {
    font-size: 14px;
  }
}
