.warningMessage {
  background-color: #ff6478;
  padding: 20px;
}

.illustPrizeEvent {
  width: 100%;
  height: auto;
}

.widthControl {
  @media screen and (max-width: 1034px) {
    font-size: 14px;
  }
}
